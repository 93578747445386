import { ChatRequest } from  "@/models/ai/chat_request.model";
import { ChatGeneratorResponse } from  "@/models/ai/chat_response.model";

export type History = ChatRequest | ChatGeneratorResponse;

export function isChatRequest(obj: any): obj is ChatRequest {
  return (obj as ChatRequest).request_type !== undefined;
}

export function isChatGeneratorResponse(obj: any): obj is ChatGeneratorResponse {
  return (obj as ChatGeneratorResponse).role !== undefined;
}