/* eslint-disable @typescript-eslint/camelcase */
export class DocumentSource {

  id: string;
  source: string;  
  title?: string;
  sections?: string;
  text?: string;
  id_category?: string;

  constructor(
              id: string,
              source: string,
              title?: string,
              sections?: string,
              text?: string,
              id_category?: string) {
    this.id = id;
    this.source = source;
    this.title = title;
    this.sections = sections;
    this.text = text;
    this.id_category = id_category
  }

  toJson(): object {
    return {
      id: this.id,
      source: this.source,
      title: this.title,
      sections: this.sections,
      text: this.text,
      id_category: this.id_category
    }
  }

}
